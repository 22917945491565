import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=34e6058c&scoped=true"
import script from "./Event.vue?vue&type=script&lang=js"
export * from "./Event.vue?vue&type=script&lang=js"
import style0 from "./Event.vue?vue&type=style&index=0&id=34e6058c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e6058c",
  null
  
)

export default component.exports